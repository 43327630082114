































import { Component, Vue } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class Login extends Vue {
  initLoading: boolean = false;
  logining: boolean = false;
  model: any = {
    userName: "",
    password: ""
  };
  rules2: any = {
    userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
    password: [{ required: true, message: "请输入密码", trigger: "blur" }]
  };
  shops: any[] = [];
  checked: boolean = false;

  handleSubmit(event: any) {
    (this.$refs.model as any).validate(async (valid: boolean) => {
      if (valid) {
        this.logining = true;
        try {
          var res = await this.$ajax.post(
            "/api/TokenAuth/Authenticate",
            this.model
          );
          if (res.data.success) {
            utils.setUserToken(res.data.result.accessToken, true);

            this.loginRedirect();
          }
        } catch {}
        this.logining = false;
      }
    });
  }
  loginRedirect() {
    this.logining = false;
    this.$notify({
      title: "登录成功",
      message: "",
      type: "success",
      duration: 2000
    });
    let q = this.$route.query;
    if (q && q.returnUrl && q.returnUrl !== "/login") {
      console.log("URL:", q.returnUrl);
      window.location.href = process.env.BASE_URL + q.returnUrl;
    } else {
      window.location.href = process.env.BASE_URL;
    }
  }
  created() {}
}
